import { docGenerate } from './docGenerate';
import * as index from './index';

export const docData = {
  date: new Date(),
  csvArray: []
};

const formatDate = date => new Date(date).toLocaleDateString();

const formatInvoice = number => {
  const now = new Date();
  const currentYear = now.getFullYear();
  return `${number}/${currentYear}`;
};

const formatItems = arr => {
  const [header, ...rows] = arr;
  const formattedHeader = header.map(e => {
    return { text: e, bold: true, fillColor: 'lightgray' };
  });
  const formattedRows = rows.map(e => {
    e.unshift({ text: e.shift(), alignment: 'left' });
    e.push({ text: e.pop(), bold: true });
    return e;
  });
  return [formattedHeader, ...formattedRows];
};

const formatLeds = () => {
  const tbl = document.getElementById('led-usage-table');
  const tblBody = document.getElementById('led-usage-tbody');
  let formattedHeader = [];
  let formattedRows = [];
  if (tblBody.hasChildNodes()) {
    const headerItems = [...tbl.tHead.rows[0].cells]
      .map(e => {
        return e.textContent;
      })
      .slice(0, 3);
    formattedHeader = headerItems.map(e => {
      return { text: e, bold: true, fillColor: 'lightgray' };
    });
    const bodyItems = [...tbl.tBodies[0].rows];
    console.log(bodyItems);
    formattedRows = bodyItems.map(e => [
      e.cells[0].firstChild.selectedOptions[0].value,
      e.cells[1].firstChild.selectedOptions[0].value,
      e.cells[2].firstChild.value
    ]);
    console.log(formattedRows);
    console.log([formattedHeader, ...formattedRows]);
  }
  return [formattedHeader, ...formattedRows];
};

export const formatDoc = () => {
  if (docData.csvArray.length == 0) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    $('.get-file-button').transition('tada');
  } else {
    const formattedDoc = {
      date: formatDate(index.inputDate.value),
      invoiceNumber: formatInvoice(index.inputInvNumber.value),
      itemsTable: formatItems(docData.csvArray),
      ledUsageTable: formatLeds(),
      signer: index.selectSigner.value,
      numPackages: index.inputPackages.value,
      comment: index.textareaComment.value
    };
    docGenerate(formattedDoc);
  }
};
