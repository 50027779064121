import { convertCSVToArray } from 'convert-csv-to-array';
import { docData } from './docFormat';
import drawPreviewTable from './drawPreviewTable';
import drawLedTable from './drawLedTable';

const handleCSVFile = e => {
  console.log(e);
  const file = e.target.files[0];
  file.text().then(data => {
    console.log(data);
    if (data.length - 1 != data.lastIndexOf('\n')) {
      data = data + '\n';
    }
    docData.csvArray = convertCSVToArray(data, {
      type: 'array'
    });
    console.log('Surowe dane z CSV:', docData.csvArray);
    removeTableIfExist('items-preview-table', 'led-usage-table');
    drawPreviewTable([...docData.csvArray]);
    drawLedTable(docData.csvArray);
  });
};

const removeTableIfExist = (...tablesID) => {
  tablesID.forEach(tableID => {
    const table = document.getElementById(tableID);
    if (table) table.remove();
  });
};

export { handleCSVFile };
