import '../style/style.css';
import 'fomantic-ui-css/semantic.css';
import 'fomantic-ui-css/semantic';
import autosize from 'autosize'
import { formatDoc } from './docFormat';
import { handleCSVFile } from './getCSV';
import config from '../config.json';

const csvInput = document.getElementById('input');
const btnGenerate = document.getElementById('btnGenerate');
export const inputDate = document.getElementById('datePicker');
export const inputInvNumber = document.getElementById('invNumber');
export const selectSigner = document.getElementById('signersList');
export const inputPackages = document.getElementById('numPackages');
export const textareaComment = document.getElementById('comment');

autosize(textareaComment);

inputDate.valueAsDate = new Date();

for (let i = 0; i < config.signers.length; i++) {
  const option = document.createElement('option');
  option.text = config.signers[i];
  selectSigner.appendChild(option);
}

csvInput.addEventListener('change', handleCSVFile);
btnGenerate.addEventListener('click', formatDoc);
