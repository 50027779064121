const drawPreviewTable = itemsArr => {
  const header = itemsArr.shift();

  const div = document.getElementById('items-preview');

  const table = document.createElement('table');
  table.id = 'items-preview-table';
  table.className = 'ui collapsing celled padded unstackable table';
  const caption = document.createElement('caption');
  caption.textContent = 'Tabela elementów zawartych w wysyłce';
  table.appendChild(caption);
  const thead = document.createElement('thead');
  const tr = document.createElement('tr');
  header.forEach(element => {
    const th = document.createElement('th');
    th.scope = 'col';
    th.textContent = element;
    tr.appendChild(th);
  });
  thead.appendChild(tr);
  table.appendChild(thead);

  const tbody = document.createElement('tbody');
  itemsArr.forEach(row => {
    const tr = document.createElement('tr');
    row.forEach((element, index) => {
      if (index == 0) {
        const th = document.createElement('th');
        th.scope = 'row';
        th.textContent = element;
        tr.appendChild(th);
      } else {
        const td = document.createElement('td');
        td.className = 'center aligned';
        td.textContent = element;
        tr.appendChild(td);
      }
    });
    tbody.appendChild(tr);
  });
  table.appendChild(tbody);

  div.appendChild(table);
  div.classList.remove('hidden');
};

export default drawPreviewTable;
