import config from '../config.json';

const drawLedTable = orders => {
  const orderNumbers = orders[0].slice(1, -1);

  const div = document.getElementById('led-usage');

  const table = document.createElement('table');
  table.id = 'led-usage-table';
  table.className = 'ui collapsing celled unstackable table';
  const caption = document.createElement('caption');
  caption.textContent = 'Zużycie diod LED';
  table.appendChild(caption);
  const thead = document.createElement('thead');
  thead.className = 'center aligned';
  const tr = document.createElement('tr');
  const header = ['Nr zamówienia', 'Typ diod LED', 'Zużyta ilość'];
  header.forEach(element => {
    const th = document.createElement('th');
    th.scope = 'col';
    th.textContent = element;
    tr.appendChild(th);
  });
  const th = document.createElement('th');
  const addBtn = document.createElement('button');
  addBtn.textContent = 'Dodaj';
  addBtn.className = 'ui button basic positive';
  addBtn.addEventListener('click', () => {
    addUsage(orderNumbers);
  });
  th.appendChild(addBtn);
  tr.appendChild(th);

  thead.appendChild(tr);
  table.appendChild(thead);
  const tbody = document.createElement('tbody');
  tbody.setAttribute('id', 'led-usage-tbody');
  table.appendChild(tbody);
  div.appendChild(table);
  div.classList.remove('hidden');
};

const addUsage = orderNumbers => {
  const tbody = document.getElementById('led-usage-tbody');
  const newRow = tbody.insertRow();
  newRow.className = 'center aligned';

  const orderCell = newRow.insertCell();
  orderCell.appendChild(addSelect(orderNumbers));

  const ledCell = newRow.insertCell();
  ledCell.appendChild(addSelect(config.leds));

  const qtyCell = newRow.insertCell();
  qtyCell.className = 'ui input';
  const input = document.createElement('input');
  input.setAttribute('type', 'number');
  qtyCell.appendChild(input);

  const addCell = newRow.insertCell();
  const btn = document.createElement('button');
  btn.textContent = 'Usuń';
  btn.className = 'ui button basic negative';
  btn.addEventListener('click', () => {
    tbody.deleteRow(newRow.rowIndex - 1);
  });
  addCell.appendChild(btn);
};

const addSelect = options => {
  const select = document.createElement('select');
  select.className = 'ui dropdown';
  options.forEach(e => {
    const option = document.createElement('option');
    option.text = e;
    select.add(option);
  });
  return select;
};

export default drawLedTable;
